import axios from '../../instance';
import { AUTH_TOKEN } from '../Constants/index'

export function authTokenUpdate(payload) {
    return { type: AUTH_TOKEN, payload };
}

export const logginUserOut = (id, device_token) => {
    return async(dispatch) => {
        const obj = {
            id,
            device_token
        }
        const res = await axios.post("/auth/logout", obj);
        if(res.data.error === false)
        {
            return {status: 200}
        }
        else
        {
            return {status: 400}
        }
    }
}