export const gettingForgetPasswordEmailValidation =
  "/v1/send_forgot_password_otp";
export const gettingForgetPasswordOtpValidation =
  "/v1/verify_forgot_password_otp";
export const gettingResetPasswordValidation = "/v1/forgot_password";
export const gettingLoggedIn = "/v1/login";
export const buyingProduct = "/v1/buy_product";

//////////////// Functions ///////////////////

export const gettingUserDetail = (id) => {
  return `/v1/get_user_profile/${id}`;
  //   return `/get_user_profile/22222`;
};

export const gettingDashboardDetail = (page) => {
  return `/v1/list_product?page=${page}`
  // return `/auth/list_product?page=${page}`;
};

export const gettingParticularProduct = (id) => {
  return `/v1/get_product/${id}`;
};

export const gettingPurchaseHistory = (page, id, type) => {
  return `/v1/user_product_history/${id}?page=${page}&statusfilter=${type}`;
};
