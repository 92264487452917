import React, { useState } from "react";
import Logo from "../../../../Assets/Images/Logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "reactstrap";
import ToggleNotification from "../../../../ReusableComponent/ToggleNotification/ToastNotification";

import { withRouter } from "react-router-dom";
import { compose } from "redux";
import IconRotate from "../../../../Assets/Images/Icon-feather-rotate.png";
import { NavLink } from "react-router-dom";
import "../../../../CSS/login.css";

const OtpValidation = (props) => {
  const [loader, setLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("OTP is Required"),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      const res = await props.otpValidation(values.otp);
      if (res && res.status === 400) {
        ToggleNotification("ForgetPasswodFail", res.message);
      } else if (res && res.status === 500) {
        ToggleNotification("ServerError");
      }
      setLoader(false);
    },
  });

  const Resend = async () =>{
    let email = localStorage.getItem("user_email")
    const res = await props.emailValidation(email);
    if (res && (res.status === 400 || res.status === 400)) {
      ToggleNotification("ServerError");
    } 
  }

  return (
    <>
      <div className="forgotPass-head-Cont">
        <div class="col-lg-12 forgotpass-head">Enter 4 digit recovery code</div>
        <div class="col-lg-12 forgotpass-subhead">
          Recovery code was sent on your email
        </div>
      </div>
      <div class="col-lg-4  login-box forgotPass-box">
        <div class="col-lg-12 login-form">
          <form onSubmit={formik.handleSubmit}>
            <label class="back_set">Recovery code</label>
            <div class="form-group dflex">
              <div class="fake-input">
                <input
                  placeholder="Recovery code here"
                  class="form-control"
                  disabled={loader}
                  id="otp"
                  name="otp"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.otp}
                  style={{marginBottom:"10px"}}
                />
                <img src={IconRotate} width="25px" alt="logo" />
              </div>
            </div>
            <div className="resend_pass" onClick={()=>Resend()}>
              Resend Otp
            </div>
            {formik.touched.otp || formik.errors.otp ? (
              <div class="validationColor mb-4">{formik.errors.otp}</div>
            ) : null}

            <div class="col-lg-12 loginbttm">
              <div class="col-lg-12 login-btm login-button">
                <button type="submit" class="btn btn-outline-primary">
                  {loader ? <Spinner /> : "SUBMIT"}
                </button>
              </div>
            </div>
          </form>
          <p className="backToLogin">
            {" "}
            Back to <NavLink to="/login">Login </NavLink>
          </p>
        </div>
        <div class="col-lg-12">
          <img src={Logo} class="logo-bottom" alt="logo" />
        </div>
      </div>
    </>
  );
};

export default compose(withRouter)(OtpValidation);
