import React, { useState } from "react";
import Logo from "../../../../Assets/Images/Logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "reactstrap";
import { NavLink } from "react-router-dom";
import "../../../../CSS/login.css";

import ToggleNotification from "../../../../ReusableComponent/ToggleNotification/ToastNotification";

const EmailValidation = (props) => {
  const [loader, seatLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is Required"),
    }),
    onSubmit: async (values) => {
      localStorage.setItem("user_email",values.email)
      seatLoader(true);
      const res = await props.emailValidation(values.email);
      if (res && res.status === 400) {
        ToggleNotification("ForgetPasswodFail", res.message);
      } else if (res && res.status === 500) {
        ToggleNotification("ServerError");
      }
      seatLoader(false);
    },
  });
  return (
    <>
      <div className="forgotPass-head-Cont">
        <div className="col-lg-12 forgotpass-head">Forgot Password</div>
        <div className="col-lg-12 forgotpass-subhead">
          Enter the email address associated with your account
        </div>
      </div>

      <div className="col-lg-4  login-box forgotPass-box">
        <div className="col-lg-12 login-form">
          <form onSubmit={formik.handleSubmit}>
            <label className="back_set">Email</label>
            <div className="form-group dflex">
              <i className="fa fa-envelope-o" aria-hidden="true"></i>
              <input
                placeholder="name@email.com"
                className="form-control"
                id="email"
                disabled={loader}
                name="email"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
            </div>
            {formik.touched.email || formik.errors.email ? (
              <div className="validationColor" style={{ marginBottom: "15px" }}>
                {formik.errors.email}
              </div>
            ) : null}

            <div className="col-lg-12 loginbttm">
              <div className="col-lg-12 login-btm login-button">
                <button
                  type="submit"
                  disabled={loader}
                  className="btn btn-outline-primary"
                >
                  {loader ? <Spinner /> : "SEND"}
                </button>
              </div>
            </div>
          </form>
          <p className="backToLogin">
            {" "}
            Back to <NavLink to="/login">Login </NavLink>
          </p>
        </div>
        <div className="col-lg-12">
          <img src={Logo} class="logo-bottom" alt="logo" />
        </div>
      </div>
    </>
  );
};

export default EmailValidation;
