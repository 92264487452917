import React, { useState } from "react";
import { Table } from "reactstrap";
import moment from "moment";
import ModifiedPopover from "../../ReusableComponent/Popover/Popover";
import DeleteConfirmationModal from "./DeleteConfirmationModal/DeleteConfirmationModal";
import { connect } from "react-redux";
import { deletingPendingRequest } from "../../Redux/Actions/purchaseHistoryAction";
import ToggleNotification from "../../ReusableComponent/ToggleNotification/ToastNotification";

const PendingTable = (props) => {

  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const getData = async() => {
    setLoader(true)
    const res = await props.deleteRequest(deleteId);
    if(res.status && res.status === 200)
    {
      props.apiHit();
      ToggleNotification('RequestDeleted', "The request has been deleted Successfully.")
    }
    else if(res.status && res.status === 500)
    {
      ToggleNotification('ServerError')
    }
    else {
      ToggleNotification('Error', "The request cannot be deleted. Please try again later.")
    }
    setModal(false);
    setLoader(false)
  }

  return (
    <>
      <DeleteConfirmationModal  loader={loader} modal={modal} setModal={setModal} getData={getData}/>
      <Table>
        <thead>
          <tr>
            <th style={{ width: "20%", textAlign: "center" }}>Product</th>
            <th style={{ width: "20%", textAlign: "center" }}>Name</th>
            <th style={{ width: "20%", textAlign: "center" }}>Redeem Points</th>
            <th style={{ width: "20%", textAlign: "center" }}>Purchase Date</th>
            <th style={{ width: "20%", textAlign: "center" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.productHistory.map((item, index) => (
            <>
              {
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>
                    <img
                      src={item?.product?.product_image}
                      className="Purchase-icon mr-4"
                      alt="Watch3"
                      width="100%"
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <ModifiedPopover
                      id={index}
                      heading="Name"
                      body={
                        item && item.product && item.product.product_name
                          ? item.product.product_name
                          : "N/A"
                      }
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {item && item.redeem_amount 
                      ? item.redeem_amount
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {moment(item?.created_at).format("L")}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <span className="fa fa-trash-o fa-lg" onClick={() => {
                      setDeleteId(item?.id)
                      setModal(true)
                      }}></span>
                  </td>
                </tr>
              }
            </>
          ))}
        </tbody>
      </Table>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteRequest: (id) => dispatch(deletingPendingRequest(id))
  }
}

export default connect(null, mapDispatchToProps)(PendingTable);
