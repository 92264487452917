import axios from "../../instance";
import * as url from "../../Constants/apiConstants";

export const gettingForgetPasswordEmailValidation = (data) => {
  return async (dispatch) => {
    const res = await axios.post(
      url.gettingForgetPasswordEmailValidation,
      data
    );
    if (res && res.data && res.data.error === false) {
      return { status: res.status };
    } else if (res && res.error && res.error === true) {
      return { status: 400, message: res.message };
    } else if (res && res.status && res.status === 500) {
      return { status: 500 };
    }
  };
};

export const gettingForgetPasswordOtpValidation = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.gettingForgetPasswordOtpValidation, data);
    if (res && res.data && res.data.error === false) {
      return { status: res.status };
    } else if (res && res.error && res.error === true) {
      return { status: 400, message: res.message };
    } else if (res && res.status && res.status === 500) {
      return { status: 500 };
    }
  };
};

export const gettingResetPasswordValidation = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.gettingResetPasswordValidation, data);
    if (res && res.data && res.data.error === false) {
      return { status: res.status };
    } else if (res && res.error && res.error === true) {
      return { status: 400, message: res.message };
    } else if (res && res.status && res.status === 500) {
      return { status: 500 };
    }
  };
};

export const gettingLoggedIn = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.gettingLoggedIn, data);
    // console.log(res);
    if (res && res.data && res.data.error === true) {
      return { status: 400, message: res.data.message };
    } else if (res && res.status && res.status === 500) {
      return { status: 500 };
    } else if (res && res.data && res.data.error === false) {
      // console.log(res);
      localStorage.setItem("quota-store-token", res?.data?.data?.jwt_token);
      localStorage.setItem("quota-store-user-id", res?.data?.data?.id);
      return { status: 200 };
    }
  };
};
