import axios from "../../instance";
import * as url from "../../Constants/apiConstants";

export const PURCHASE_HISTORY = "PURCHASE_HISTORY";

export const gettingPurchaseHistory = (page, type) => {
  return async (dispatch) => {
    const res = await axios.get(
      url.gettingPurchaseHistory(
        page,
        localStorage.getItem("quota-store-user-id"),
        type
      )
    );
    return res;
  };
};

export const deletingPendingRequest = (id) => {
  return async(dispatch) => {
    const res = await axios.get(`v1/delete_pending_request/${id}`);
    if(res.data && res.data.error === false)
    {
      return {status: 200}
    }
    else if(res.status && res.status === 500) {
      return {status: 500}
    }
    else {
      return {status:400}
    }
  }
};
