import React from "react";
import { Table } from "reactstrap";
import moment from "moment";
import ModifiedPopover from "../../ReusableComponent/Popover/Popover";


const RejectedTable = (props) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th style={{ width: "25%", textAlign: "center" }}>Product</th>
            <th style={{ width: "25%", textAlign: "center" }}>Name</th>
            <th style={{ width: "25%", textAlign: "center" }}>Redeem Points</th>
            <th style={{ width: "25%", textAlign: "center" }}>Rejected On</th>
          </tr>
        </thead>
        <tbody>
          {props.productHistory.map((item, index) => (
            <>
              {
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>
                    <img
                      src={item?.product?.product_image}
                      className="Purchase-icon mr-4"
                      alt="Watch3"
                      width="100%"
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <ModifiedPopover
                      id={index}
                      heading="Name"
                      body={
                        item && item.product && item.product.product_name
                          ? item.product.product_name
                          : "N/A"
                      }
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {item && item.redeem_amount && item.redeem_amount
                      ? item.redeem_amount
                      : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {moment(item?.created_at).format("L")}
                  </td>
                </tr>
              }
            </>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default RejectedTable;