import { combineReducers } from "redux";
import { userDetails } from "./UserDetails";
import { authToken } from "./authToken";
import Dashboard from "../Reducers/dashboardReducer";
import Navbar from "../Reducers/navbarReducer";

const rootReducer = combineReducers({
  userDetails,
  authToken,
  dashboard: Dashboard,
  navbar: Navbar,
});

export default rootReducer;
