import axios from "./instance";
import firebase from "firebase/app";
import "firebase/messaging";
var firebaseConfig = {
  apiKey: "AIzaSyBcCuEKekDCygaW0FiWs-ubcQ01vzWbtEs",
  authDomain: "quota-games.firebaseapp.com",
  projectId: "quota-games",
  storageBucket: "quota-games.appspot.com",
  messagingSenderId: "805023434431",
  appId: "1:805023434431:web:edc64f194d59f92398e80a",
  measurementId: "G-HG9QKMJPPM",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

let messaging = null;
if (firebase.messaging.isSupported()){
    messaging = firebase.messaging();
}

export const getToken = (setTokenFound) => {
  if(messaging)
  {

    return messaging
      .getToken({
        vapidKey:
          "BHjVrwVM8eVmmQ6felu3pmSVd4tBZAbLEkX4N4ggAFaFE6g1Pm64Y6UvMeb0FJeHdIAA7RtTY6g8QcnjJyaDDjk",
      })
      .then((currentToken) => {
        if (currentToken) {
          // console.log("current token for client: ", currentToken);
          localStorage.setItem('qdt', currentToken);
          axios
            .post("/auth/web-notification-token", {
              id: localStorage.getItem("quota-store-user-id"),
              device_token: currentToken,
            })
            .then((res) => console.log(res))
            .catch((err) => console.log(err));
          setTokenFound(true);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          setTokenFound(false);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // catch error while creating client token
      });
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
