import React, { useEffect, useState } from "react";
import profile from "../../Assets/Images/dummyUser.png";
import dollar from "../../Assets/Images/dollar.png";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { gettingUserDetail } from "../../Redux/Actions/headerAction";
import * as constants from "../../Constants/appConstants";
import { changingNavbarState } from "../../Redux/Actions/navbarAction";
import "./Header.css"
import { getToken, onMessageListener } from "../../firebase";

const Header = (props) => {
  const [spentCoins, setSpentCoins] = useState("");
  const [totalPoints, setToalPoints] = useState("");

  useEffect(() => {
    getData();
    getToken(setTokenFound);
  }, []);

  const getData = async () => {
    const res = await props.gettinguserDetail(
      constants.id ? constants.id : null
    );
  };

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);
  // getToken(setTokenFound);

  useEffect(() => {
    onMessageListener().then(payload => {
      setShow(true);
      setNotification({title: payload.notification.title, body: payload.notification.body})
      // console.log(payload);
    }).catch(err => console.log('failed: ', err));
  },[])

  return (
    <>
      <div className="header">
        <div className="mobileView">
        <a href="#" className="onoff-btn">
          <i
            className="fa fa-lg fa-bars"
            onClick={() => props.changeStatus(true)}
          ></i>
        </a>
        <div className="col-xl-8 col-lg-6 col-md-4 profileLogoParent1">
            <div className="profileLogo">
              <img
                src={props.user_detail && props.user_detail.profile_image ? props.user_detail.profile_image : profile}
                className="userProfile"
                // className="Profile-pic"
                alt="profile"
              />
              <strong  className="profileName">{props.user_detail?.name}</strong>
            </div>
          </div>
          </div>
        <div className="row " style={{ width: "100%" }}>
          <div className="col-xl-2 col-lg-3 col-md-4 credPoints">
            <div className="Available">
              <p className="header-text">
                <img src={dollar} className="redeem-icon" alt="dollar" />{" "}
                {props.user_detail?.total_points}
              </p>
              <p className="head-text">Available Credits</p>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 credPoints">
            <div className="Redeem">
              <p className="header-text">
                <img src={dollar} className="redeem-icon" alt="dollar" />{" "}
                {props.user_detail?.spent_points}
              </p>
              <p className="head-text">Redeemed Credits</p>
            </div>
          </div>
          <div className="col-xl-8 col-lg-6 col-md-4 profileLogoParent2">
            <div className="profileLogo">
              <img
                src={props.user_detail && props.user_detail.profile_image ? props.user_detail.profile_image : profile}
                className="userProfile"
                // className="Profile-pic"
                alt="profile"
              />
              <strong>{props.user_detail?.name}</strong>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProp = (state) => {
  return {
    user_detail: state.userDetails.user_detail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    gettinguserDetail: (id) => dispatch(gettingUserDetail(id)),
    changeStatus: (data) => dispatch(changingNavbarState(data)),
  };
};

export default connect(mapStateToProp, mapDispatchToProps)(withRouter(Header));
