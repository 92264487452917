import axios from "../../instance";
import * as url from "../../Constants/apiConstants";

export const USER_DETAIL = "USER_DETAIL";

export const getUserDetail = (data) => {
  return {
    type: USER_DETAIL,
    data: data,
  };
};

export const gettingUserDetail = (
  id = localStorage.getItem("quota-store-user-id")
) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingUserDetail(id));
    if (res && res.data && res.data.error === false) {
      dispatch(getUserDetail(res.data.data.user_profile));
      return { status: 200 };
    } else if (res && res.data && res.data.error === true) {
      return { status: 400, message: res.data.message };
    } else if (res && res.status && res.status === 500) {
      return { status: 500 };
    }
  };
};
