import { NotificationManager } from "react-notifications";

const ToggleNotification = (type, message = null) => {
  const renderNotification = () => {
    switch (type) {
      ////////////////// Success Notifications /////////////////////////////

      case "Success Login":
        return NotificationManager.success(
          "You have been successfully Logged In",
          "Login Successfull",
          3000
        );
      case "ForgetPasswordSuccess":
        return NotificationManager.success(
          "Your Password has been reset Successfully",
          "Password Reset Successfull",
          3000
        );
      case "Logout":
        return NotificationManager.success(
          "Your have been successfully Logged Out",
          "Logout Successfull",
          3000
        );

        case "RequestDeleted":
        return NotificationManager.success(
          message,
          "Request Deleted",
          3000
        );

      /////////////////// Error Notifications //////////////////////////////////////

      case "Login Fail":
        return NotificationManager.error(
          message,
          "Login Failed",
          3000
        );
      case "ForgetPasswodFail":
        return NotificationManager.error(message, "Error", 3000);
      case "Error":{
        return NotificationManager.error(message, "Error", 3000);
      }
      ////////////////// Sever Error ///////////////////////////////////////////

      case "ServerError":
        return NotificationManager.error(
          `Server Error ! Please try again later`,
          "Server Error",
          3000
        );
      default:
        return null;
    }
  };
  return renderNotification();
};

export default ToggleNotification;
