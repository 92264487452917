import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { SidebarData } from "../SidebarData/SidebarData";
import "./Navbar.css";
import { changingNavbarState } from "../../../Redux/Actions/navbarAction";

const Navbar = (props) => {
  const showSidebar = () => props.changeStatus();

  return (
    <>
      {/* <div className="navbar">
        <Link to="#" className="menu-bars">
          <i className="fa fa-bars" onClick={showSidebar}></i>
        </Link>
      </div> */}
      <nav className={props.sidebar ? "nav-menu active" : "nav-menu"}>
        <ul className="nav-menu-items">
          <li className="navbar-toggle">
            <div className="sidebar-brand">
              <div className="brand-name">Company Store</div>
            </div>
            <Link to="#" className="menu-bars">
              <i className="fa fa-close" onClick={showSidebar}></i>
            </Link>
          </li>
          <ul className="sidebar-nav">
            <li
              className={
                window.location.pathname === "/dashboard" ? "active" : ""
              }
            >
              <Link to="/dashboard">
                <i className="fa fa-home"></i>Home
              </Link>
            </li>
            <li
              className={
                window.location.pathname === "/purchase-history" ? "active" : ""
              }
            >
              <Link to="/purchase-history">
                <i class="fa fa-history" aria-hidden="true"></i>Purchase History
              </Link>
            </li>
            <li>
              <Link to="">
                <i class="fa fa-sign-out" ></i>Log out
              </Link>
            </li>
          </ul>
          {/* {SidebarData.map((item, index) => {
            return (
              <li key={index} className={item.cName}>
                <Link to={item.path}>
                  <span>{item.title}</span>
                </Link>
              </li>
            );
          })} */}
        </ul>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sidebar: state.navbar.sideBar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeStatus: () => dispatch(changingNavbarState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
