import React, { useState, useEffect } from "react";
import Logo from "../../../Assets/Images/Logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "reactstrap";

import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { gettingLoggedIn } from "../../../Redux/Actions/authenticationAction";
import ToggleNotification from "../../../ReusableComponent/ToggleNotification/ToastNotification";

const Login = (props) => {
  let history = useHistory();

  useEffect(() => {
    makeDeviceId();
  }, []);

  const [passwordShown, setPasswordShown] = useState(false);
  const [loader, setLoader] = useState(false);
  const [deviceToken, setDeviceToken] = useState("");

  const makeDeviceId = async () => {
    let length = 30;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += await characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }
    setDeviceToken(result);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",

      // device_token: deviceToken,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is Required"),
      password: Yup.string()
        .required("Password is Required"),
    }),
    onSubmit: async (values) => {
      //   alert(JSON.stringify(values, null, 2));
      setLoader(true);
      const data = {
        email: values.email,
        login_type: "web",
        password: values.password,
      };
      const res = await props.loggingIn(data);
      // console.log(res);
      if (res && res.status === 200) {
        window.location.href = "/dashboard";
      } else if (res && res.status === 400) {
        ToggleNotification("Login Fail", res.message);
      } else if (res && res.status === 500) {
        ToggleNotification("ServerError");
      }
      setLoader(false);
    },
  });

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleForgotPassword = () => {
    history.push("/forgot-password");
  };

  return (
    <>
      <div className="loginContainer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4  login-box">
              <div className="col-lg-12 sub-title "> Company Store</div>
              <div className="col-lg-12 login-title">Log In!</div>
              <div className="col-lg-12 login-form">
                <form onSubmit={formik.handleSubmit}>
                  <label className="back_set">Email</label>
                  <div className="form-group dflex">
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                    <input
                      placeholder="name@email.comm"
                      className="form-control form-disable"
                      disabled={loader}
                      id="email"
                      name="email"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                  </div>
                  {formik.touched.email || formik.errors.email ? (
                    <div className="validationColor">{formik.errors.email}</div>
                  ) : null}

                  <label className="back_set2">Password</label>
                  <div className="form-group dflex">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    <input
                      type={passwordShown ? "text" : "password"}
                      placeholder="******"
                      disabled={loader}
                      className="form-control form-disable"
                      id="password"
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    <i
                      className="fa fa-eye cursorPointer"
                      aria-hidden="true"
                      onClick={togglePasswordVisiblity}
                    ></i>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="validationColor">
                      {formik.errors.password}
                    </div>
                  ) : null}
                  <div
                    className="forgot-password cursorPointer"
                    onClick={handleForgotPassword}
                    disabled={loader}
                  >
                    Forgot Password?
                  </div>

                  <div className="col-lg-12 loginbttm">
                    <div className="col-lg-12 login-btm login-button">
                      <button
                        type="submit"
                        className="btn btn-outline-primary form-disable"
                        disabled={loader}
                      >
                        {loader ? <Spinner /> : "LOGIN"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-12">
                <img src={Logo} className="logo-bottom" alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggingIn: (data) => dispatch(gettingLoggedIn(data)),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Login));
