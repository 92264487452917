import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner} from 'reactstrap';

const DeleteConfirmationModal = (props) => {
    return (
        <>
            <Modal isOpen={props.modal}>
                <ModalHeader>
                    Delete Request Confirmation
                </ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this Purchase request ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={props.loader} color="primary" onClick={props.getData}>
                        {
                            props.loader ? <Spinner/> : "Yes"
                        }
                    </Button>
                    <Button color="danger" disabled={props.loader} onClick={() => props.setModal(false)}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default DeleteConfirmationModal;