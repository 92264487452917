import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "../Reducers/RootReducers"
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'authType',
    storage: storage,
    // blacklist: ['firebase_db']// which reducer want to store
  };

const pReducer = persistReducer(persistConfig, rootReducer); 


const middlewares = [thunk, createDebounce()]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  pReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
)

const persistor = persistStore(store)


export { persistor, store }
