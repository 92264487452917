import React from "react";
import { Modal, Button } from "antd";
import check from "../../Assets/Images/check.png";

import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { userDetails } from "../../Redux/Actions/UserDetails";

const CongratulatePurchaseModal = ({
  setIsModalVisibles,
  isModalVisibles,
  clickProductDetail,
  setUserDetails,
  user_detail,
}) => {
  const showModal = () => {
    setIsModalVisibles(true);
  };

  const handleOk = () => {
    setIsModalVisibles(false);
  };

  const handleCancel = () => {
    setIsModalVisibles(false);
  };

  return (
    <>
      <Modal
        title=""
        centered
        visible={isModalVisibles}
        // visible={true}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={check} className="check-icon" alt="check" />
        <p className="cong-head">Please wait while we get your request approved by Administrator.</p>
        <p className="light-font">
          We have raised your request for redemption of <br/> <span className="highlight">{clickProductDetail?.product_name}</span>
          {/* You have successfully redeemed your hard earned credits with " */}
          {/* <span className="highlight">{clickProductDetail?.product_name}</span>" */}
        </p>
        <p className="remain">
          {" "}
          Remaining Credits:{" "}
          <span className="cardbox-span">{user_detail.total_points}</span>
        </p>
        <Button type="primary" className="cong-btn" onClick={handleCancel}>
          Finish
        </Button>
      </Modal>
    </>
  );
};

const mapStateToProp = (state) => {
  return {
    user_detail: state.userDetails.user_detail,
    auth_token: state.authToken.auth_token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserDetails: (userData) => dispatch(userDetails(userData)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(CongratulatePurchaseModal));
