import React from "react";
import { Modal, Button } from "antd";
import error from "../../Assets/Images/errorIcon.jpg";
import "./ErrorModal.css";

const ErrorModal = ({ setIsModalVisibles, isModalVisibles, message }) => {
  const showModal = () => {
    setIsModalVisibles(true);
  };

  const handleOk = () => {
    setIsModalVisibles(false);
  };

  const handleCancel = () => {
    setIsModalVisibles(false);
  };

  return (
    <>
      <Modal
        title=""
        centered
        visible={isModalVisibles}
        footer={null}
        onCancel={handleCancel}
      >
        <div className="errorLogo">
          <img src={error} className="error-icon" alt="Error" />
        </div>
        <p className="cong-head">Whoops..!!</p>
        <p className="light-font">
          {message}.
          <br />
          Please try again later.{" "}
        </p>
        <Button type="danger" className="error-btn" onClick={handleCancel}>
          Close
        </Button>
      </Modal>
    </>
  );
};

export default ErrorModal;
