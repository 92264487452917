import React, { useState, useEffect } from "react";
import RedeemModal from "../Components/Modals/RedeemModal";
import Pagination from "react-js-pagination";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  gettingDashboardDetail,
  gettingParticularProduct,
} from "../Redux/Actions/dashboardAction";
import ModifiedLoader from "../ReusableComponent/Loader/loader";

const Dashboard = ({ product, user_detail, getAllProduct }) => {
  const handleRedeemModal = async (data) => {
    // let response = await getProduct(id);
    // console.log("resposne_product_by_id", response);
    setClickProductDeatil(data);
    setIsModalVisible(true);
  };

  const handlePageChange = async (pageNumber) => {
    await setCurrentPage(pageNumber);
    getProductList(pageNumber);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState("");
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState("");
  const [loaderState, setLoaderState] = useState(true);
  const [clickProductDetail, setClickProductDeatil] = useState(true);

  const getProductList = async (pageNumber = 1) => {
    setLoaderState(true);

    let response = await getAllProduct(pageNumber);
    setLoaderState(false);
    return response;
  };

  const getData = async () => {
    let response = await getProductList();
    if (response.data.response) {
      setLoaderState(false);
      let total_records = response.data.total * 1;
      const pageRange = total_records / 10;
      let range = Math.ceil(pageRange);
      setTotalItemsCount(total_records);
      setPageRangeDisplayed(range);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const renderProducts =
    product && product.length > 0 ? (
      product.map((item, index) => {
        return (
          <div className="col-lg-3 col-md-4 col-sm-6 mb-2" key={index}>
            <div className="card">
              <img
                src={item.product_image}
                className="card-img-top"
                alt="Watch3"
              />
              <div className="card-body">
                <h5 className="card-title"> {item.product_name}</h5>
                <p className="card-text">{item.product_price} Coins</p>
                <button
                  href="#"
                  disabled={
                    user_detail &&
                    user_detail.total_points &&
                    user_detail.total_points >= item.product_price
                      ? false
                      : true
                  }
                  className="btn btn-primary Redeem-btn"
                  onClick={() => handleRedeemModal(item)}
                >
                  REDEEM NOW
                </button>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div className="forgotpass-head">Product Not Availaible</div>
    );

  return (
    <>
      <RedeemModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        clickProductDetail={clickProductDetail}
      />
      <div className="content-area">
        <div className="box">
          <div className="row">
            {loaderState ? (
              <>
                {/* <div className="loader-center"> */}
                <ModifiedLoader
                  type="Puff"
                  color="#00BFFF"
                  height={100}
                  width={100}
                />
                {/* </div> */}
              </>
            ) : (
              renderProducts
            )}

            {!loaderState && totalItemsCount > 0 ? (
              <>
                {" "}
                <div className="pagination-center mt-4">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={currentPage}
                    itemsCountPerPage={10}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={pageRangeDisplayed*1}
                    onChange={handlePageChange}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProp = (state) => {
  return {
    user_detail: state.userDetails.user_detail,
    product: state.dashboard.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllProduct: (page) => dispatch(gettingDashboardDetail(page)),
    getProduct: (id) => dispatch(gettingParticularProduct(id)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(Dashboard));
