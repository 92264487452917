import React from "react";
import logo1 from "../../Assets/Images/logo1.png";

import { withRouter } from "react-router-dom";
import { Link,useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { authTokenUpdate, logginUserOut } from "../../Redux/Actions/authToken";
import { userDetails } from "../../Redux/Actions/UserDetails";
import ToggleNotification from "../../ReusableComponent/ToggleNotification/ToastNotification";

import { changingNavbarState } from "../../Redux/Actions/navbarAction";

const Sidebar = (props) => {
  const history = useHistory();
  const showSidebar = () => props.changeStatus(false);

  const handleActiveSidebar = async(value) => {
    switch (value) {
      case 2:
        const res = await props.logUserOut(localStorage.getItem('quota-store-user-id'),localStorage.getItem('qdt'))
        if(res.status === 200) 
        {
          localStorage.removeItem("quota-store-token");
          localStorage.removeItem("quota-store-user-id");
          localStorage.removeItem("qdt");
          window.location.href = "/login";
        }
        else
        {
          ToggleNotification('Error', 'Something went wrong, please try after sometime')
        }
        break;
      default:
        return;
    }
  };
  return (
    <>
      {/* <aside id="sidebar-wrapper"> */}
      <nav className={props.sidebar ? "nav-menu active" : "nav-menu"}>
        <ul className="nav-menu-items">
          <div className="sidebar-brand">
            <div className="brand-name" style={{cursor:"pointer"}} onClick={()=>history.push('/dashboard')}>
              Company Store{" "}
              <span onClick={showSidebar} className="closeSidebarButton" style={{ color: "white", position:"absolute", top:"10px", right:"9px" }}>
                <i className="close-btn fa fa-close"></i>
              </span>
            </div>
          </div>
          <ul className="sidebar-nav">
            <li
              className={
                window.location.pathname === "/dashboard" ? "active" : ""
              }
              onClick={showSidebar}
            >
              <Link to="/dashboard">
                <i className="fa fa-home"></i>Home
              </Link>
            </li>
            <li
              className={
                window.location.pathname === "/purchase-history" ? "active" : ""
              }
              onClick={showSidebar}
            >
              <Link to="/purchase-history">
                <i class="fa fa-history" aria-hidden="true"></i>Purchase History
              </Link>
            </li>
            <li onClick={showSidebar}>
              <Link href="" onClick={() => handleActiveSidebar(2)}>
                <i class="fa fa-sign-out"></i>Log out
              </Link>
            </li>
          </ul>
          <img src={logo1} className="sidebar-bottom-logo" alt="logo1" />
        </ul>
      </nav>
      {/* </aside> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sidebar: state.navbar.sideBar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeStatus: (data) => dispatch(changingNavbarState(data)),
    logUserOut: (id, token) => dispatch(logginUserOut(id, token))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
