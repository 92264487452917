import React, {useEffect} from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";
import AuthenticatedRoute from "./AuthenticatedRoute";
import Login from "../Containers/Authentication/Login/Login";
import Dashboard from "../Containers/Dashboard";
import ForgotPassword from "../Containers/Authentication/ForgotPassword/ForgotPassword";
import PurchaseHistory from "../Containers/PurchaseHistory";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import Navbar from "../Components/newSidebar/Navbar/Navbar";
import firebase from "../firebase";

const Routes = () => {

  // useEffect(() => {
  //   const messaging = firebase.messaging();
  //   messaging.requestPermission().then(() => {return messaging.getToken()}).then(token=> {
  //     console.log('Token', token).catch((err) => console.log(err));
  //   })
  // },[])

  const isAuthenticated = localStorage.getItem("quota-store-token");

  return (
    <BrowserRouter>
      <Switch>
        <AuthenticatedRoute path="/login" component={Login} />
        <AuthenticatedRoute
          path="/forgot-password"
          component={ForgotPassword}
        />
      </Switch>
      {/* <Navbar /> */}
      {/* <Sidebar /> */}
      {isAuthenticated ? (
        // <div id="wrapper">
        <>
          <div className="col-2">
            <Sidebar />
          </div>
          <div className="content-wrap">
            <div id="content-wrapper">
              <Header />
              <Switch>
                <Route exact path="/dashboard" component={Dashboard} />
                <Route
                  exact
                  path="/purchase-history"
                  component={PurchaseHistory}
                />
                <Redirect to="/dashboard" />
              </Switch>
            </div>
          </div>
        </>
      ) : (
        /* <section id="content-wrapper">
          <Header />
          <Switch>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route
          exact
          path="/purchase-history"
          component={PurchaseHistory}
          />
          <Redirect to="/dashboard" />
          </Switch>
          </section> */
        /* </div> */
        <Redirect to="/login" />
      )}
      {/* {isAuthenticated ? <Redirect to="/" /> : <Redirect to="/login" />} */}
    </BrowserRouter>
  );
};

export default Routes;
