import React, { useState } from "react";
import { Modal, Button } from "antd";
import ContagulatePurchaseModal from "./CongratulatePurchaseModal";
import dollar from "../../Assets/Images/dollar.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userDetails } from "../../Redux/Actions/UserDetails";
import { buyingProduct } from "../../Redux/Actions/dashboardAction";
import { gettingUserDetail } from "../../Redux/Actions/headerAction";
import { Spinner } from "reactstrap";
import ErrorModal from "../../ReusableComponent/ErrorModal/ErrorModal";

const RedeemModal = (props) => {
  const [isModalVisibles, setIsModalVisibles] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')

  const handleModalContratulate = async () => {
    setLoader(true);
    let values = {
      product_id: props.clickProductDetail.id,
      user_id: props.user_detail.id,
    };
    let response = await props.buyProduct(values);
    await props.getUserDetail();
    // console.log(response)
    if (response?.data?.error === false) {
      setIsModalVisibles(true);
      props.setIsModalVisible(false);
    }
    else if(response?.data?.error === true)
    {
      setErrorModal(true);
      setErrorMessage("Something Went Wrong")
      props.setIsModalVisible(false);
    } 
    else if(response.error) {
      setErrorModal(true);
      setErrorMessage(response?.message)
      props.setIsModalVisible(false);
    }
    setLoader(false);
  };

  const handleOk = () => {
    props.setIsModalVisible(false);
  };

  const handleCancel = () => {
    props.setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title=""
        centered
        visible={props.isModalVisible}
        closable={!loader}
        footer={null}
        onCancel={handleCancel}
      >
        <b className="redeem-heading">Are you sure want to continue?</b>
        <div className="cardbox">
          <div className="cardbox-img">
            <img
              src={props.clickProductDetail.product_image}
              className="redeem-img"
              alt="Watch3"
            />
          </div>
          <div className="cardbox-content">
            <h2 className="redeem-title">
              {props.clickProductDetail.product_name}
            </h2>
            <p className="redeem-price">
              {" "}
              <img src={dollar} className="redeem-icon" alt="dollar" />
              {props.clickProductDetail.product_price}
            </p>
          </div>
        </div>
        <p className="redeem-footer-text">
          Available Credits:{" "}
          <span className="cardbox-span">{props.user_detail.total_points}</span>
        </p>
        <Button
          type="primary"
          className="redeem-btn"
          disabled={loader}
          onClick={handleModalContratulate}
        >
          {loader ? <Spinner /> : "CONTINUE PURCHASE"}
        </Button>
      </Modal>
      <ContagulatePurchaseModal
        isModalVisibles={isModalVisibles}
        setIsModalVisibles={setIsModalVisibles}
        clickProductDetail={props.clickProductDetail}
      />
      <ErrorModal
        isModalVisibles={errorModal}
        setIsModalVisibles={setErrorModal}
        message={errorMessage}
      />
    </>
  );
};

const mapStateToProp = (state) => {
  return {
    user_detail: state.userDetails.user_detail,
    auth_token: state.authToken.auth_token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserDetails: (userData) => dispatch(userDetails(userData)),
    buyProduct: (data) => dispatch(buyingProduct(data)),
    getUserDetail: (id) => dispatch(gettingUserDetail(id)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(RedeemModal));
