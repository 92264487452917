import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Logo from "../../../../Assets/Images/Logo.png";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { compose } from "redux";
import { Spinner } from "reactstrap";
import "../../../../CSS/login.css";
import ToggleNotification from "../../../../ReusableComponent/ToggleNotification/ToastNotification";

const Schema = Yup.object().shape({
  password: Yup.string().required("This field is required"),
  changepassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    ),
  }),
});

const ResetPassword = (props) => {
  let history = useHistory();
  const [loader, setLoader] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  return (
    <>
      <Formik
        initialValues={{
          password: "",
          changepassword: "",
        }}
        validationSchema={Schema}
        onSubmit={async (values) => {
          setLoader(true);
          const res = await props.resetPassword(values.password);
          if (res && res.status === 400) {
            ToggleNotification("ForgetPasswodFail", res.message);
          } else if (res && res.status === 500) {
            ToggleNotification("ServerError");
          }
          setLoader(false);
        }}
      >
        {({ values, errors, handleSubmit, handleChange, handleBlur }) => {
          return (
            <>
              <div className="forgotPass-head-Cont">
                <div className="col-lg-12 forgotpass-head">
                  Reset your Password
                </div>
                <div className="col-lg-6 forgotpass-subhead">
                  Please enter your new password twice so we can verify you
                  typed it correctly
                </div>
              </div>
              <div className="col-lg-4  login-box forgotPass-box">
                <div className="col-lg-12 login-form">
                  <form onSubmit={handleSubmit}>
                    <label className="back_set">Password</label>
                    <div>
                      <div className="form-group dflex">
                        <i className="fa fa-lock " aria-hidden="true"></i>
                        <input
                          placeholder="******"
                          className="form-control"
                          disabled={loader}
                          id="password"
                          type={confirmPasswordShown ? "text" : "password"}
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                        />
                        <i
                          className="fa fa-eye cursorPointer"
                          aria-hidden="true"
                          onClick={toggleConfirmPasswordVisiblity}
                        ></i>
                      </div>
                      <p
                        className="validationColor"
                        style={{
                          marginBottom: "0px",
                          marginLeft: "10px !important",
                        }}
                      >
                        {errors.password}
                      </p>
                    </div>

                    <label className="back_set2">Confirm Password</label>
                    <div>
                      <div className="form-group dflex">
                        <i className="fa fa-lock" aria-hidden="true"></i>
                        <input
                          type={passwordShown ? "text" : "password"}
                          placeholder="******"
                          className="form-control"
                          disabled={loader}
                          id="changepassword"
                          name="changepassword"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.changepassword}
                        />
                        <i
                          className="fa fa-eye cursorPointer"
                          aria-hidden="true"
                          onClick={togglePasswordVisiblity}
                        ></i>
                      </div>
                      <p
                        className="validationColor"
                        style={{
                          marginBottom: "0px",
                          marginLeft: "10px !important",
                        }}
                      >
                        {errors.changepassword}
                      </p>
                    </div>

                    <div className="col-lg-12 loginbttm">
                      <div className="col-lg-12 login-btm login-button">
                        <button
                          type="submit"
                          className="btn btn-outline-primary"
                        >
                          {loader ? <Spinner /> : "Submit"}
                        </button>
                      </div>
                    </div>
                  </form>
                  {/* <p className="backToLogin">
                          {" "}
                          Back to <NavLink to="/login">Login </NavLink>
                        </p> */}
                </div>
                <div className="col-lg-12">
                  <img src={Logo} className="logo-bottom" alt="logo" />
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default compose(withRouter)(ResetPassword);
