import * as actionTypes from "../Actions/headerAction";

const initialValues = {
  user_detail: "",
};

export function userDetails(state = initialValues, action) {
  switch (action.type) {
    case actionTypes.USER_DETAIL: {
      // console.log("action.payload",action.payload)
      // console.log("this has been called >>>>>>>>>>>>>>>>>>>>", action.data);
      return {
        ...state,
        user_detail: action.data,
      };
    }

    default:
      return state;
  }
}
