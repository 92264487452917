import axios from "axios";

const instance = axios.create({
   baseURL: "https://quota.dmlabs.in/api",
  // baseURL: "https://quota-api.cvbusinesssolutions.com/api/",
  //  baseURL: "https://api.quotagames.com/api",
  // headers: {
  //   Authorization: localStorage.getItem("quota-store-token")
  //     ? `Bearer ${localStorage.getItem("quota-store-token")}`
  //     : null,
  //   "Content-Type": "application/json",
  //   accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // },
});

instance.interceptors.request.use(
  async (config) => {
    config.headers["Authorization"] = await `Bearer ${localStorage.getItem(
      "quota-store-token"
    )}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    // console.log(
    //   err && err.response && err.response.status
    // );
    const originalRequest = err.config;
    if (err.response.status === 401 ){
      loggingOutUser();
    } else if (
      err.response.status === 403
    ) {
      let res = await axios.post(
        `https://quota.dmlabs.in/api/v1/refresh_jwt_token`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "quota-store-token"
            )}`,
          },
        }
      );
      if (res && res.data && res.data.error === false) {
        unAutherized(res.data.data);
        originalRequest.headers = {
          Authorization: ` Bearer ${res.data.data.refresh_token}`,
        };
        return instance(originalRequest);
      } else if (res && res.data && res.data.error === true) {
        loggingOutUser();
      }
    } else {
      return err && err.response && err.response.data
        ? err.response.data
        : { status: 500 };
    }
  }
);

const unAutherized = async (data) => {
  localStorage.setItem("quota-store-token", data.refresh_token);
};

const loggingOutUser = () => {
  if (localStorage.getItem("quota-store-token")) {
    window.location.href = "/login";
    localStorage.removeItem("quota-store-token");
    localStorage.removeItem("quota-store-user-id");
  }
};

export default instance;
