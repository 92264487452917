import React, { useState } from "react";
import "../../../CSS/login.css";
import { useHistory } from "react-router-dom";
import EmailValidation from "../ForgotPassword/EmailValidation/EmailValidation";
import OtpValidation from "../ForgotPassword/Otpvalidation/OtpValidation";
import ResetPassword from "../ForgotPassword/ResetPasswordValidation/ResetPassword";
import { connect } from "react-redux";
import {
  gettingForgetPasswordEmailValidation,
  gettingForgetPasswordOtpValidation,
  gettingResetPasswordValidation,
} from "../../../Redux/Actions/authenticationAction";
import ToggleNotification from "../../../ReusableComponent/ToggleNotification/ToastNotification";

const ForgotPassword = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [render, setRender] = useState("email");

  const emailValidation = async (email) => {
    setEmail(email);
    const data = {
      email,
    };
    const res = await props.forgetPasswordEmail(data);
    if (res.status === 200) {
      setRender("otp");
    } else return res;
  };

  const otpValidation = async (otp) => {
    const data = {
      email: email,
      otp: otp,
    };
    const res = await props.forgetPasswordOtp(data);
    if (res.status === 200) {
      setRender("reset");
    } else return res;
  };

  const resetPassword = async (password) => {
    const data = {
      email: email,
      password: password,
    };
    const res = await props.forgetPasswordReset(data);
    if (res.status === 200) {
      ToggleNotification("ForgetPasswordSuccess");
      history.replace("/login");
    } else return res;
  };

  return (
    <>
      <div className="loginContainer">
        <div className="container">
          <div className="row">
            {render === "email" ? (
              <EmailValidation emailValidation={emailValidation} />
            ) : render === "otp" ? (
              <OtpValidation otpValidation={otpValidation} emailValidation={emailValidation} />
            ) : render === "reset" ? (
              <ResetPassword resetPassword={resetPassword} />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgetPasswordEmail: (data) =>
      dispatch(gettingForgetPasswordEmailValidation(data)),
    forgetPasswordOtp: (data) =>
      dispatch(gettingForgetPasswordOtpValidation(data)),
    forgetPasswordReset: (data) =>
      dispatch(gettingResetPasswordValidation(data)),
  };
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
