import { AUTH_TOKEN } from "../Constants/index";

const initialValues = {
  auth_token: "",
};

export function authToken(state = initialValues, action) {
  switch (action.type) {
    case AUTH_TOKEN: {
      // console.log("action.payload",action.payload)
      return {
        ...state,
        auth_token: action.payload,
      };
    }

    default:
      return state;
  }
}
