import axios from "../../instance";
import * as url from "../../Constants/apiConstants";

export const DASHBOARD_DETAIL = "DASHBOARD_DETAIL";

export const getDashboardDetail = (data) => {
  return {
    type: DASHBOARD_DETAIL,
    data: data,
  };
};

export const gettingDashboardDetail = (page) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingDashboardDetail(page));
    if (res && res.data && res.data.error === false) {
      dispatch(getDashboardDetail(res.data?.response));
    }
    return res;
  };
};

export const gettingParticularProduct = (id) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingParticularProduct(id));
    return res;
  };
};

export const buyingProduct = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.buyingProduct, data);
    return res;
  };
};
