import React from "react";
import { Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

const ProtectedRoute = ({ component: Component, auth_token, ...rest }) => {
  const isAuthenticated = localStorage.getItem("quota-store-token");
  // console.log("this", isAuthenticated);

  return (
    // <Route {...rest} render={
    //   props => {
    //     if (token!==null) {
    //       return <Component {...rest} {...props} />
    //     } else {
    //       return <Redirect to="/login" />
    //     }
    //   }
    // } />
    <Route
      {...rest}
      render={
        (props) =>
          !isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to="/dashboard" />
          )
        // <Component {...props} />
      }
    />
  );
};

export default ProtectedRoute;
