import logo from "./logo.svg";
// import './App.css';
import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { history } from "./history";
import Routes from "./Routes/Routes";
import { messaging } from "./Services/Firebase";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getToken, onMessageListener } from "./firebase";
// import './Assets/Css/Style.css';
// import Spinner from '../src/Components/Spinner/index';
// import { messaging } from "./init-fcm";
// ...

function App() {
  //----------------- SHubham's code start ------------------------- //

  // useEffect(() => {
  //   const messaging = getMessaging();
  //   console.log(messaging)
  //   getToken(messaging, { vapidKey: 'BKOc_FBd4dOPSrk8wQbBsHytrX5HzK4uXZw0D59R-QlNGvXaye-u-7IKSn7uNuf4UbwBk9zZ6zj7di2ohKsSpNg' }).then((currentToken) => {
  //     if (currentToken) {
  //       console.log('token', currentToken)
  //       // Send the token to your server and update the UI if necessary
  //       // ...
  //     } else {
  //       // Show permission request UI
  //       console.log('No registration token available. Request permission to generate one.');
  //       // ...
  //     }
  //   }).catch((err) => {
  //     console.log('An error occurred while retrieving token. ', err);
  //     // ...
  //   });
  //   onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload);
  //     // ...
  //   });

  // }, [onMessage]);

  // onMessage(messaging, (payload) => {
  //   console.log('Message received. ', payload);
  //   // ...
  // });

  // ---------------------- SHubham's code end --------------------- //

  // const [show, setShow] = useState(false);
  // const [notification, setNotification] = useState({ title: "", body: "" });
  // const [isTokenFound, setTokenFound] = useState(false);
  // getToken(setTokenFound);

  // useEffect(() => {
  //   onMessageListener()
  //     .then((payload) => {
  //       // setShow(true);
  //       // setNotification({title: payload.notification.title, body: payload.notification.body})
  //       console.log(payload);
  //     })
  //     .catch((err) => console.log("failed: ", err));
  // });

  return (
    <>
      {/* <Suspense fallback={<Spinner />}> */}
      <Routes />
      {/* </Suspense> */}
    </>
  );
}

export default App;
