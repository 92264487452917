import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardHeader, CardBody } from "reactstrap";
import Pagination from "react-js-pagination";
import { gettingPurchaseHistory } from "../Redux/Actions/purchaseHistoryAction";
import { Table } from "reactstrap";
import NoData from "../ReusableComponent/NoDataError/NoDataError";
import "./PurchaseHistory.css";
import ModifiedLoader from "../ReusableComponent/Loader/loader";
import PendingTable from "./PendingTable/PendingTable";
import PurchaseTable from "./PurchaseTable/PurchaseTable";
import RejectedTable from "./RejectedTable/RejectedTable";

const PurchaseHistory = ({ user_detail, auth_token, getPurchaseHiistory }) => {
  const [productHistory, setProductHistory] = useState([]);
  const [loaderState, setLoaderState] = useState(true);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState("");
  const [page, setPage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState("");
  const [dropdown, setDropdown] = useState(1);

  const handlePageChange = async (pageNumber) => {
    await setPage(pageNumber);
    getProductHistory(pageNumber);
  };

  const getProductList = async (currentPage = page, type) => {
    setLoaderState(true);
    let response = await getPurchaseHiistory(currentPage, type);
    setLoaderState(false);
    return response;
  };

  const getProductHistory = async (pageNumber, type = dropdown) => {
    const response = await getProductList(pageNumber, type);
    // console.log(response);
    if (response.data && response.data.error === false) {
      // const data = response.data.response;
      const total_records = response.data.total;
      const pageRange = total_records / 10;
      let range = Math.ceil(pageRange);
      setTotalItemsCount(total_records);
      setProductHistory(response.data.response);
      setPageRangeDisplayed(range);
      setLoaderState(false);
    }
  };

  useEffect(() => {
    getProductHistory();
  }, []);

  return (
    <>
      <Card className="purchaseHistory">
        <CardHeader>
          <div className="purchaseHistoryHeader">
            <h3>Purchase History</h3>
            <select
              defaultValue={dropdown}
              className="col-12 col-md-2 purchaseHistorySelect"
              onChange={(e) => {
                setDropdown(e.target.value);
                getProductHistory(1, e.target.value);
              }}
            >
              <option value={1}>Purchased</option>
              <option value={0}>Pending</option>
              <option value={2}>Rejected</option>
            </select>
          </div>
        </CardHeader>
        <CardBody>
          <Table responsive>
            {loaderState ? (
              <ModifiedLoader />
            ) : (
              <>
                {productHistory.length > 0 ? (
                  dropdown === 1 ? (
                    <PurchaseTable productHistory={productHistory} />
                  ) : dropdown == 0 ? (
                    <PendingTable productHistory={productHistory} apiHit={() => getProductHistory(1)} />
                  ) : (
                    <RejectedTable productHistory={productHistory} />
                  )
                ) : (
                  <NoData message="No Purchase History" />
                )}
              </>
            )}
          </Table>
          {!loaderState ? (
            totalItemsCount > 0 ? (
              <>
                {" "}
                <div className="pagination-center mt-4">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={page}
                    itemsCountPerPage={10}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={pageRangeDisplayed}
                    onChange={handlePageChange}
                  />
                </div>
              </>
            ) : null
          ) : null}
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProp = (state) => {
  return {
    auth_token: state.authToken.auth_token,
    user_detail: state.userDetails.user_detail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPurchaseHiistory: (page, type) =>
      dispatch(gettingPurchaseHistory(page, type)),
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(withRouter(PurchaseHistory));
