import React from "react";
import One from "./Img/empty-box.png";
import { Button } from "reactstrap";
// import { useHistory } from "react-router-dom";
import "./NoDataError.css";

const NoDataError = (props) => {
  // const history = useHistory();
  return (
    <div className="noDataError">
      <img src={One} />
      <h1>{props.message}</h1>
    </div>
  );
};

export default NoDataError;
